<template>
  <div class="profile-body"
    v-if="$oidc.isAuthenticated && $oidc.userProfile && $oidc.userProfile.roles && $oidc.userProfile.roles.length > 0">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="edit-con button-reg">
      <b-button pill class="asmt-bt my-btn" size="lg" @click="backToProfile()">
        <span class="primary-color btn-text">Back To My Profile</span>
      </b-button>
    </div>
    <div class="main-con">
      <MyProfile />
    </div>
    <div class="user-search-con">
      <SearchUser :user="userList" @userSelected="userSelected($event)"></SearchUser>
    </div>
    <div class="edit-pr-con">
      <div>
        <div class="recent-users">Profile Details:</div>
      </div>
      <div class="target-card-lable edit-user-imf">
        <div class="img-con">
          <div v-if="myDetails.profilePicUrl">
            <b-img :src="myDetails.profilePicUrl" rounded="circle" alt="" width="55" height="55" class="pro-img-border"
              @error="setAltImg()"></b-img>
          </div>
          <div class="no-img-my" v-else>
            <span class="profile-sh">{{ myDetails.userName | shortName }}</span>
          </div>
        </div>

        <div class="font-sb">
          <h4>{{ myDetails.userName }}</h4>
        </div>
      </div>
      <div class="row user-field-con">
        <div class="col-lg-4 col-md-4 col-sm-4 col-top">
          <label for="pillar" class="form-label select-lable lable-text">Email Id</label>
          <b-form-input class="form-control select-text select-text-pd text-class" id="emailId" :value="myDetails.emailId"
            disabled> </b-form-input>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-top">
          <label for="pillar" class="form-label select-lable lable-text">Country Code</label>
          <b-form-input class="form-control select-text select-text-pd user-role form-control" id="contactNo"
            v-model="countryCode" @keydown="numberKeydown($event, 1)" autocomplete="off" step="1" maxlength="5"
            @keypress.native="isNumber($event)">
          </b-form-input>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-top">
          <label for="pillar" class="form-label select-lable lable-text">Contact Number</label>
          <b-form-input class="form-control select-text select-text-pd user-role form-control" id="contactNo"
            v-model="contactNumber" @keydown="numberKeydown($event, 1)" autocomplete="off" step="1" minlength="10"
            maxlength="10" @keypress.native="isNumber($event)">
          </b-form-input>
        </div>
      </div>
      <div class="row user-field-con">
        <div class="col-lg-4 col-md-4 col-sm-4 col-top" v-if="masterData">
          <!-- <label for="pillar" class="form-label select-lable lable-text">User Goodyear ID<span class="req">*</span></label> -->
          <label for="pillar" class="form-label select-lable lable-text">User Goodyear ID</label>
          <b-input class="form-control select-text select-text-pd user-role" id="uId" v-model="uId"
            @keydown="nameKeydown($event)" placeholder="Enter Goodyear ID" @change="convertToUpper()" step="1"
            minlength="7" maxlength="7">
          </b-input>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-top">
          <label for="pillar" class="form-label select-lable lable-text"> Manager Goodyear ID </label>
          <search-user :user="managerList" id="search-user" @userSelected="afterSelecting($event)" />
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-top" v-show="checkPersonaGlobalForCalibration()">
          <label for="pillar" class="form-label select-lable lable-text"> Allow Assessment Calibration </label>
          <!-- <select class="form-control select-text select-text-pd cust-st-icon" id="isAllowedCalibration" v-model="selectedChoice" 
 @change="onchangeAllowCalibration($event)"> -->
          <select class="form-control select-text select-text-pd cust-st-icon" id="isAllowedCalibration"
            v-model="selectedChoice">
            <option value="0" disabled>Select Option</option>
            <option v-for="choice in choices" :key="choice.choiceId" :value="choice.choiceOption">
              {{ choice.choiceOption }}
            </option>
          </select>
        </div>
        <hr class="active-tab-hr" />
      </div>
      <div v-for="(count, index) in          roleArr         " :key="index">
        <div class="row user-field-con">
          <div class="col-lg-4 col-md-4 col-sm-4 col-top" v-if="masterData">
            <label for="pillar" class="form-label select-lable lable-text"> User Role </label>
            <b-form-input class="form-control select-text select-text-pd user-role form-control" disabled id="userId"
              v-model="count.userRole"> </b-form-input>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-top" v-if="count.userRole == 'User' || count.userRole == 'Plant Sub Pillar Leader' || count.userRole == 'Global Pillar Leader' ||
            count.userRole == 'Plant Pillar Leader' || count.userRole == 'Regional Pillar Leader'">
            <label for="pillar" class="form-label select-lable lable-text">Pillar Name<span class="req"
                v-if="checkPillar(count.userRole) && count.regionId != 1">*</span></label>
            <select class="form-control select-text select-text-pd cust-st-icon" id="pillar" v-model="count.pillarId">
              <option value="0" :disabled="count.regionId != 1 && count.userRole !== 'User'">Select Pillar</option>
              <option v-for="pillarDropdown in masterData.pillarDropdown" :key="pillarDropdown.pillarId"
                :value="pillarDropdown.pillarId">
                {{ pillarDropdown.pillarName }}
              </option>
            </select>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-top" v-if="count.userRole == 'User' ||
            count.userRole == 'Plant Sub Pillar Leader' ||
            count.userRole == 'Plant PO Leader' ||
            count.userRole == 'Plant Pillar Leader' ||
            count.userRole == 'Plant Manager' ||
            count.userRole == 'Regional PO Leader' ||
            count.userRole == 'Regional Pillar Leader'
            ">
            <!-- <label for="pillar" class="form-label select-lable lable-text">Region<span class="req">*</span></label> -->
            <label for="pillar" class="form-label select-lable lable-text">Region</label>
            <select class="form-control select-text select-text-pd cust-st-icon" id="pillar" v-model="count.regionId"
              @change="roleName = count.userRole; onchangeRegion(index, count.regionId);">
              <option value="0" disabled>Select Region</option>
              <option v-for="        regionDrop         in         masterData.regionDropdown        "
                :key="regionDrop.regionId" :value="regionDrop.regionId">
                {{ regionDrop.regionName }}
              </option>
            </select>
          </div>
        </div>
        <div class="row user-field-con" v-if="count.userRole == 'User' || count.userRole == 'Plant Sub Pillar Leader' || count.userRole == 'Plant PO Leader' ||
          count.userRole == 'Plant Pillar Leader' || count.userRole == 'Plant Manager'
          ">
          <div class="col-lg-4 col-md-4 col-sm-4 col-top" v-if="count.userRole == 'User' || count.userRole == 'Plant Sub Pillar Leader' || count.userRole == 'Plant PO Leader' ||
            count.userRole == 'Plant Pillar Leader' || count.userRole == 'Plant Manager'
            ">
            <label for="pillar" class="form-label select-lable lable-text">
              Plant
              <span class="req" v-if="checkPlant(count.userRole) && count.regionId != 1">*</span>
            </label>
            <select class="form-control select-text select-text-pd cust-st-icon user-role" :id="'plantId' + index"
              v-model="count.plantId" :disabled="count.regionId == 1 || count.regionId == null || count.regionId == 0">
              <option value="0" :disabled="count.regionId != 1 && count.userRole !== 'User'">Select Plant</option>
            </select>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-top"
            v-if="count.userRole == 'User' || count.userRole == 'Plant Sub Pillar Leader'">
            <label for="pillar" class="form-label select-lable lable-text">Plant Area</label>
            <select class="form-control select-text select-text-pd cust-st-icon" id="pillar" v-model="count.plantAreaId">
              <option value="0">Select Plant Area</option>
              <option v-for="    plantAreaDropdown     in     masterData.plantAreaDropdown    "
                :key="plantAreaDropdown.plantAreaId" :value="plantAreaDropdown.plantAreaId">
                {{ plantAreaDropdown.plantAreaName }}
              </option>
            </select>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-top" v-if="count.userRole == 'Regional PO Leader' || count.userRole == 'Regional Pillar Leader' || count.userRole == 'Plant PO Leader' || count.userRole == 'Plant Pillar Leader'
            ">
            <label for="pillar" class="form-label select-lable lable-text">Business Unit</label>
            <select class="form-control select-text select-text-pd cust-st-icon" id="pillar"
              v-model="count.bussinessUnitId">
              <option value="0">Select Business Unit</option>
              <option v-for="    bussinessUnitDropdown     in     masterData.bussinessUnitDropdown    "
                :key="bussinessUnitDropdown.bussinessUnitId" :value="bussinessUnitDropdown.bussinessUnitId">
                {{ bussinessUnitDropdown.bussinessUnitName }}
              </option>
            </select>
          </div>
        </div>

        <div class="row user-field-con" v-if="count.userRole == 'Energy User'">
          <!-- {{ $oidc.userProfile.roles }} -->
          <!-- Energy Region -->

          <div class="col-lg-4 col-md-4 col-sm-4 col-top">
            <label for="pillar" class="form-label select-lable lable-text">Energy Region<span class="req">*</span></label>
            <select class="form-control select-text select-text-pd cust-st-icon user-role" v-model="regionId"
              :disabled="$oidc.userProfile.roles.includes(roles.POENERGYUSER)" @change=" onchangeEnergyRegion($event)">
              <option value="0" disabled>Select Region</option>
              <option v-for="   regionDrop      in      EnergyRegionData     " :key="regionDrop.energyRegionId"
                :value="regionDrop.energyRegionId">
                {{ regionDrop.energyRegionName }}
              </option>
            </select>
          </div>

          <!-- Energy Plant -->
          <div class="col-lg-4 col-md-4 col-sm-4 col-top">
            <label for="pillar" class="form-label select-lable lable-text">Energy Plant<span class="req"
                v-if="checkPlant && regionId != 1">*</span></label>
            <select class="form-control select-text select-text-pd cust-st-icon user-role" id="plantId"
              :disabled="$oidc.userProfile.roles.includes(roles.POENERGYUSER)" v-model="plantId"
              @change=" onchangeEnergyPlant($event)">
              <option value="0" disabled>Select Plant</option>
              <option v-for="     plantDropdown      in      plantDropdownForEnergy     "
                v-bind:key="plantDropdown.energyPlantId" v-bind:value="plantDropdown.energyPlantId">
                {{ plantDropdown.energyPlantName }}
              </option>
            </select>
          </div>
          <span style="color: red; font-size: 14px; padding: 1rem"
            v-if="$oidc.userProfile.roles.includes(roles.POENERGYUSER) && count.userRole == 'Energy User'">
            Note:Please contact your regional administrator to change plant.
          </span>
        </div>

        <hr class="active-tab-hr" v-if="index < roleArr.length - 1" />
      </div>

      <div style="margin-top: 10px">
        <b-button v-model="saveButton" v-if="addClk == false" style="margin-top: 10px" pill v-b-modal.messagePopup
          class="primar-btn submit-btn" size="lg" @click=" saveRole(roleArr.length - 1)">
          Save Role
        </b-button>
      </div>
    </div>
    <div class="mat-info-con add-action-footer-info border-top" style="padding-bottom: 4rem">
      <b-button class="primar-btn discard-btn" size="lg" @click=" discardEdit()"> Discard </b-button>
      <b-button v-b-modal.messagePopup pill class="primar-btn submit-btn" size="lg" @click=" saveDetails()"> Save
      </b-button>
    </div>
    <MessagePopup :routToBack="true" :message="errorMsg" :show="showPopup" :headText="headText" />
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/Breadcrumb.vue";
import Loader from "../../components/common/Loader.vue";
import MyProfile from "./MyProfile.vue";
import SearchUser from "./SearchUser.vue";
import Api from "../../common/constants/api";
import ApiCalls from "../../common/api/apiCalls";
import MessagePopup from "@/components/common/MessagePopup.vue";
import Roles from "@/common/constants/roles";
import { Element } from "chart.js";
export default {
  name: "ConfigureUser",
  props: { inx: Number },
  data() {
    return {
      // apiCount: 0,
      selectedChoice: "",
      choices: [
        {
          choiceId: 1,
          choiceOption: "Yes"
        },
        {
          choiceId: 2,
          choiceOption: "No"
        }
      ],
      roles: Roles,
      selectedOpt: false,
      showPopup: false,
      headText: "",
      reqRaise: "",
      errorMsg: "",
      plantSelected: false,
      plantDropdown: [],
      showLoader: false,
      selectedUSer: "",
      userList: [],
      selectedUSerId: "",
      plantId: "",
      pillar: "",
      masterData: {},
      pillars: [],
      plantList: [],
      plantDropdownForEnergy: [],
      pillarId: "",
      regionId: "",
      userId: 0,
      bussinessUnitId: "",
      planId: "",
      contactno: "",
      emailid: "",
      address: "",
      myDetails: [],
      countryCode: "",
      contactNumber: "",
      email_id: "",
      regionCode: "",
      regionDropdown: [],
      userroleidDrop: [],
      roleDetails: [],
      userRoleDropdowns: [],
      roleplantId: "",
      userRoleDropdownUp: [],
      roleName: "",
      mId: "",
      uId: "",
      count: 1,
      addClk: true,
      noOfsec: [1],
      selectRole: true,
      managerList: [],
      iId: "",
      saveRoleArr: [],
      addButton: 0,
      saveButton: 0,
      roleIndex: 0,
      selectedRegion: false,
      edit: 0,
      indexs: 0,
      userIdFromAPI: 0,
      EnergyRegion: [],
      EnergyRegionData: [],
      energyRegionId: "",
      energyPlantId: "",
      regid: 0,
      roleArr: []
    };
  },
  components: {
    Breadcrumb,
    Loader,
    MyProfile,
    SearchUser,
    MessagePopup
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 0,
      subMenu: 4
    });
    this.$store.dispatch("breadcrumb", {
      title: "Configure User Profile",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "My Profile / ", primary: true, redirect: "/profile" },
        { name: "Configure User Profile", primary: false }
      ]
    });
  },
  computed: {},
  created() {
    if (
      !(
        this.$oidc.userProfile.roles.includes(this.roles.CORPORATE) ||
        this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.CODELELVEADMIN) ||
        this.$oidc.userProfile.roles.includes(this.roles.SYSTEMLEVELADMIN) ||
        this.$oidc.userProfile.roles.includes(this.roles.FUNCTIONALADMIN)
      )
    ) {
      this.$router.push("/profile");
      return;
    } else {
      this.email_id = this.$route.params.id;
      // this.getRegionEnergy();
      this.getMasterData();
      this.getListOfUsers();
      this.getManagerDropdown();
    }
  },
  methods: {
    afterSelecting(eve) {
      this.mId = eve;
    },
    numberKeydown(e, loc) {
      const key = e.key;
      if (key === ".") return e.preventDefault();
      if (key === "e") return e.preventDefault();
      if (key === "+" || key === "-" || key === "*" || key === "%" || key === "#" || key === "&" || key === "^" || key === " ") return e.preventDefault();
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && isNaN(Number(evt.key)) && charCode !== 46) {
        evt.preventDefault();
      }
      return true;
    },
    convertToUpper() {
      this.uId = this.uId.toUpperCase();
    },
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      } else {
        // this.uId = this.uId.toUpperCase()
      }
    },

    saveRole(ind) {
      this.showLoader = true;
      this.showPopup = false;
      if (this.roleArr[ind].userRoleId == null || this.roleArr[ind].userRoleId == undefined || this.roleArr[ind].userRoleId == "" || this.roleArr[ind].userRoleId == 0) {
        this.showLoader = false;
        this.showPopup = true;
        this.errorMsg = "Please select a Role.";
        this.headText = "";
        return;
      } else {
        this.showPopup = false;
        let index = this.masterData.userRoleDropdown.findIndex((val) => Number(val.roleId) === Number(this.roleArr[ind].userRoleId));
        if (index !== -1) {
          this.roleName = this.masterData.userRoleDropdown[index].roleName;
          for (const element of this.saveRoleArr) {
            if (element.userRoleId == this.roleArr[ind].userRoleId) {
              this.showLoader = false;
              this.showPopup = true;
              this.errorMsg = "Selected Role already exists.";
              this.headText = "";
              this.roleArr.splice(ind, 1);
              this.addClk = true;
              return;
            }
          }

          if (
            (this.roleName == "User" ||
              this.roleName == "Plant Sub Pillar Leader" ||
              this.roleName == "Global Pillar Leader" ||
              this.roleName == "Plant Pillar Leader" ||
              this.roleName == "Regional Pillar Leader") &&
            this.roleArr[ind].regionId != 1 &&
            (this.roleArr[ind].pillarId == "" || this.roleArr[ind].pillarId == undefined || this.roleArr[ind].pillarId == null || this.roleArr[ind].pillarId == 0)
          ) {
            this.showLoader = false;
            this.showPopup = true;
            this.errorMsg = "Please select Pillar.";
            this.headText = "";
            this.showLoader = false;
            return;
          } else if (
            (this.roleName == "User" ||
              this.roleName == "Plant Sub Pillar Leader" ||
              this.roleName == "Plant PO Leader" ||
              this.roleName == "Plant Pillar Leader" ||
              this.roleName == "Plant Manager") &&
            this.roleArr[ind].regionId != "1" &&
            (this.roleArr[ind].plantId == "" || this.roleArr[ind].plantId == undefined || this.roleArr[ind].plantId == null || this.roleArr[ind].plantId == "0")
          ) {
            this.showLoader = false;
            this.showPopup = true;
            this.errorMsg = "Please select the Plant.";
            return;
          } else {
            this.saveRoleArr = [];
            for (let i = 0; i < ind; i++) {
              this.saveRoleArr.push(this.roleArr[i]);
            }
            this.saveRoleArr.push(this.roleArr[ind]);
            this.showPopup = false;
            this.showLoader = false;
            this.addClk = true;
          }
        }
      }
    },
    checkPersonaGlobalForCalibration() {
      return !!this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER);
    },
    deleteRole(ind, edit, userroleid) {
      if (edit == 0) {
        this.addClk = true;
        this.roleArr.splice(ind, 1);
        this.saveRoleArr.splice(ind, 1);
      } else {
        let checkArr = [];
        for (const element of this.myDetails.roleDetails) {
          checkArr.push(element.userRoleId);
        }
        if (checkArr.includes(userroleid)) {
          let url = Api.DELETEUSERROLE + this.userIdFromAPI + "/" + userroleid;
          ApiCalls.httpCallDetails(url, "delete").then((data) => {
            this.roleArr.splice(ind, 1);
            this.saveRoleArr.splice(ind, 1);
          });
        } else {
          this.roleArr.splice(ind, 1);
          this.saveRoleArr.splice(ind, 1);
        }
      }
    },
    checkPlant(role) {
      return role == "Plant Sub Pillar Leader" || role == "Plant PO Leader" || role == "Plant Pillar Leader" || role == "Plant Manager";
    },
    checkPillar(role) {
      return role == "Global Pillar Leader" || role == "Plant Sub Pillar Leader" || role == "Plant Pillar Leader" || role == "Regional Pillar Leader";
    },

    addRole(indexx) {
      this.showPopup = false;

      let Arr = {
        buisnessUnitId: null,
        bussinessUnit: null,
        pillarCode: null,
        pillarId: null,
        pillarName: null,
        plantArea: null,
        plantAreaId: null,
        plantId: null,
        plantName: null,
        regionId: null,
        regionName: null,
        userRole: null,
        userRoleId: null
      };
      this.roleArr.push(Arr);
      this.roleArr[indexx + 1].userRoleId = 0;
      this.selectRole = false;
      this.addClk = false;
    },
    setAltImg() {
      this.myDetails.profilePicUrl = "";
    },
    userSelected(id) {
      this.getUserIdData(id);
    },
    backToProfile() {
      this.$router.push("/profile");
    },
    getManagerDropdown() {
      ApiCalls.httpCallDetails(Api.MANAGERLISTDROPDOWN, "get").then((data) => {
        data.data.forEach((element) => {
          element.email = element.userId;
        });
        this.managerList = data.data;
      });
      this.mId = 0;
    },
    getListOfUsers() {
      this.showLoader = true;
      ApiCalls.httpCallDetails(Api.GETUNCONFIGUREDUSERS, "get").then((data) => {
        this.userList = data.data;

        this.showLoader = false;
      });
    },
    getUserIdData(id) {
      this.userroleidDrop = [];
      this.userRoleDropdownUp = [];
      this.roleArr = [];
      this.saveRoleArr = [];
      ApiCalls.httpCallDetails(Api.EDITPROFILEROLE + id, "get").then((data) => {
        this.myDetails = data.data;
        this.countryCode = this.myDetails.countryCode ? this.myDetails.countryCode : "";
        this.contactNumber = this.myDetails.contactNumber ? this.myDetails.contactNumber : "";
        console.log("this.myDetails.allowCalibrate");
        console.log(this.myDetails.allowCalibrate);
        this.selectedChoice = this.myDetails.allowCalibrate == "Y" ? "Yes" : "No";
        this.userIdFromAPI = data.data.userId;
        this.mId = this.myDetails.managerId ? this.myDetails.managerId : 0;
        this.uId = this.myDetails.gyUserId ? this.myDetails.gyUserId : null;
        for (let i = 0; i < this.myDetails.roleDetails.length; i++) {
          this.roleArr.push(data.data.roleDetails[i]);
          this.roleArr[i].pillarId = this.roleArr[i].pillarId ? this.roleArr[i].pillarId : 0;

          this.roleArr[i].regionId = this.roleArr[i].regionId ? this.roleArr[i].regionId : 0;

          this.regionId = this.roleArr[i].regionId ? this.roleArr[i].regionId : 0;
          this.plantId = this.roleArr[i].plantId ? this.roleArr[i].plantId : 0;
          this.getRegionEnergy();
          this.roleArr[i].plantAreaId = this.roleArr[i].plantAreaId ? this.roleArr[i].plantAreaId : 0;
          if (this.roleArr[i].regionId == 0 || this.roleArr[i].regionId == null || this.roleArr[i].regionId == 1)
            this.roleArr[i].plantId = this.roleArr[i].plantId ? this.roleArr[i].plantId : 0;

          this.roleArr[i].bussinessUnitId = this.roleArr[i].bussinessUnitId ? this.roleArr[i].bussinessUnitId : 0;
          this.roleArr[i].userRoleId = this.roleArr[i].userRoleId ? this.roleArr[i].userRoleId : 0;
          this.saveRoleArr.push(data.data.roleDetails[i]);

          if (
            this.myDetails.roleDetails[i].regionId &&
            this.myDetails.roleDetails[i].regionId != null &&
            this.myDetails.roleDetails[i].regionId != undefined &&
            this.myDetails.roleDetails[i].regionId != ""
          ) {
            this.plantdropDownFunc(this.myDetails.roleDetails[i].regionId, i, this.myDetails.roleDetails[i].plantId);
          }
        }
      });
    },
    getDropdownValue(ind, roleid) {
      for (const element of this.saveRoleArr) {
        if (element.userRoleId == this.roleArr[ind].userRoleId) {
          this.showLoader = false;
          this.showPopup = true;
          this.errorMsg = "Selected Role already exists.";
          this.headText = "";
          this.count.userRoleId = 0;
          return;
        }
      }
      let indexP = this.masterData.userRoleDropdown.findIndex((val) => Number(val.roleId) === Number(roleid));
      if (indexP !== -1) {
        this.showLoader = false;
        this.showPopup = false;
        this.roleArr[ind].userRoleId = this.masterData.userRoleDropdown[indexP].roleId;
        this.roleArr[ind].userRole = this.masterData.userRoleDropdown[indexP].roleName;
        this.roleArr[ind].pillarId = this.roleArr[ind].pillarId ? this.roleArr[ind].pillarId : 0;
        this.roleArr[ind].regionId = this.roleArr[ind].regionId ? this.roleArr[ind].regionId : 0;
        this.roleArr[ind].plantId = this.roleArr[ind].plantId ? this.roleArr[ind].plantId : 0;

        this.roleArr[ind].plantAreaId = this.roleArr[ind].plantAreaId ? this.roleArr[ind].plantAreaId : 0;
        if (this.roleArr[ind].regionId == 0 || this.roleArr[ind].regionId == null || this.roleArr[ind].regionId == 1)
          this.roleArr[ind].bussinessUnitId = this.roleArr[ind].bussinessUnitId ? this.roleArr[ind].bussinessUnitId : 0;
        this.roleArr[ind].userRoleId = this.roleArr[ind].userRoleId ? this.roleArr[ind].userRoleId : 0;
      }
    },
    plantdropDownFunc(regionid, ind, plantid) {
      if (
        this.roleArr[ind].userRole == "User" ||
        this.roleArr[ind].userRole == "Plant Sub Pillar Leader" ||
        this.roleArr[ind].userRole == "Plant PO Leader" ||
        this.roleArr[ind].userRole == "Plant Pillar Leader" ||
        this.roleArr[ind].userRole == "Plant Manager"
      ) {
        let url = Api.USERPROFILEPLANTDROPDOWN + regionid;
        ApiCalls.httpCallDetails(url, "get").then((data) => {
          let x = document.getElementById("plantId" + ind);
          while (x.firstChild) {
            x.remove(x.firstChild);
          }
          let elementTemp = document.createElement("OPTION");
          elementTemp.setAttribute("value", 0);
          let t = document.createTextNode("Select Plant");
          if (this.roleArr[ind].userRole !== "User") elementTemp.disabled = true;
          elementTemp.selected = true;
          elementTemp.appendChild(t);
          document.getElementById("plantId" + ind).appendChild(elementTemp);
          for (let el of data.data) {
            let element = document.createElement("OPTION");
            element.setAttribute("value", el.plantId);
            let t = document.createTextNode(el.plantName + ", " + el.regionCode);
            element.appendChild(t);
            document.getElementById("plantId" + ind).appendChild(element);
          }
          this.showLoader = false;
          this.roleArr[ind].plantId = plantid;
        });
      }
      // this.getPlantDataForEnegy(regionid, plantid);
    },

    getPlantData(i, regionid) {
      if (regionid != 1 && regionid != null && regionid != "" && regionid != 0 && regionid != undefined) {
        let url = Api.USERPROFILEPLANTDROPDOWN + this.regionid;
        ApiCalls.httpCallDetails(url, "get").then((data) => {
          this.showLoader = false;
          this.plantDropdown = data.data;
          this.roleArr[i].plantId = 0;
          this.selectedRegion = false;
        });
      } else {
        this.plantDropdown = [];
        this.showLoader = false;
      }
    },
    onchangeRegion(ind, regionId) {
      this.plantSelected = true;
      this.plantdropDownFunc(regionId, ind, 0);
    },
    onchangeAllowCalibration(event) {
      console.log(event.target.value);
    },
    getMasterData() {
      let url = Api.GETMASTERDATA;
      let userRoledrop = [];
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        this.masterData = data.data;
        this.getUserIdData(this.email_id);
        userRoledrop = this.masterData.userRoleDropdown.filter((item, i) => {
          if (
            item.roleName != "Code Admin" &&
            item.roleName != "Functional Admin" &&
            item.roleName != "System Admin" &&
            item.roleName != "Corporate" &&
            item.roleName != "Global PO Leader"
          ) {
            return item;
          }
        });
        this.masterData.userRoleDropdown = userRoledrop;
        this.regionDropdown = data.data.regionDropdown;
        this.userRoleDropdowns = data.data.userRoleDropdown;
        this.userRoleDropdownUp = data.data.userRoleDropdown;
      });
    },
    filterKey(e) {
      const key = e.key;
      if (key === ".") return e.preventDefault();
      if (key === "e") return e.preventDefault();
      if (key === "+" || key === "-" || key === "*" || key === "%" || key === "#" || key === "&" || key === "^" || key === " " || key === "!" || key === "@" || key === "(")
        return e.preventDefault();
    },
    saveDetails() {
      this.showLoader = true;
      this.showPopup = false;

      if (this.addClk === false) {
        this.showLoader = false;
        this.showPopup = true;

        this.errorMsg = "Please save the Role.";
        return;
      } else {
        this.showLoader = true;
        this.saveRoleArr = [];
        this.showPopup = false;
        let cnt = 0;
        for (const element of this.roleArr) {
          cnt = cnt + 1;
          if (element.userRoleId == null || element.userRoleId == undefined || element.userRoleId == "" || element.userRoleId == 0) {
            this.showLoader = false;
            this.showPopup = true;
            this.errorMsg = "Please Select the Role.";
            return;
          } else {
            let index = this.masterData.userRoleDropdown.findIndex((val) => Number(val.roleId) === Number(element.userRoleId));
            if (index !== -1) {
              this.roleName = this.masterData.userRoleDropdown[index].roleName;

              if (
                (this.roleName == "Plant Sub Pillar Leader" ||
                  this.roleName == "Global Pillar Leader" ||
                  this.roleName == "Plant Pillar Leader" ||
                  this.roleName == "Regional Pillar Leader") &&
                element.regionId != 1 &&
                (element.pillarId == "" || element.pillarId == undefined || element.pillarId == null || element.pillarId == 0)
              ) {
                this.showLoader = false;
                this.showPopup = true;
                this.errorMsg = "Please Select the Pillar.";
                return;
              } else if (
                (this.roleName == "Plant Sub Pillar Leader" ||
                  this.roleName == "Plant PO Leader" ||
                  this.roleName == "Plant Pillar Leader" ||
                  this.roleName == "Plant Manager") &&
                element.regionId != "1" &&
                (element.plantId == "" || element.plantId == undefined || element.plantId == null || element.plantId == "0")
              ) {
                this.showLoader = false;
                this.showPopup = true;
                this.errorMsg = "Please select the Plant.";
                return;
              } else {
                this.showLoader = false;
                this.showPopup = false;
                this.saveRoleArr.push(element);
              }
            }
          }
        }
      }
      console.log(JSON.parse(JSON.stringify(this.roleArr)), JSON.parse(JSON.stringify(this.saveRoleArr)));
      const saveDetailArr = [];
      this.saveRoleArr.forEach((ele) => {
        let regionId = ele.userRoleId === 14 || ele.userRoleId === 15 ? (this.regionId ? this.regionId != 0 ? this.regionId : null : null) : (ele.regionId ? (Number(ele.regionId) == 0 ? null : ele.regionId) : null)
        let plantId = ele.userRoleId === 14 || ele.userRoleId === 15 ? (this.plantId ? this.plantId != 0 ? this.plantId : null : null) : (ele.plantId ? (Number(ele.plantId) == 0 ? null : Number(ele.plantId)) : null)
        let detailsave = {
          roleId: ele.userRoleId ? (Number(ele.userRoleId) == 0 ? null : ele.userRoleId) : null,
          pillarId: ele.pillarId ? (Number(ele.pillarId) == 0 ? null : ele.pillarId) : null,
          regionId: regionId, // ele.regionId ? (Number(ele.regionId) == 0 ? null : ele.regionId) : null,
          plantId: plantId,  //ele.plantId ? (Number(ele.plantId) == 0 ? null : Number(ele.plantId)) : null,


          // regionId: this.regionId ? this.regionId != 0 ? this.regionId : null : null,
          // plantId: this.plantId ? this.plantId != 0 ? this.plantId : null : null,

          plantAreaId: ele.plantAreaId ? (Number(ele.plantAreaId) == 0 ? null : Number(ele.plantAreaId)) : null,
          buisnessUnitId: ele.bussinessUnitId ? (Number(ele.bussinessUnitId) == 0 ? null : Number(ele.bussinessUnitId)) : null
        };
        console.log(JSON.parse(JSON.stringify(detailsave)));
        saveDetailArr.push(JSON.parse(JSON.stringify(detailsave)));
      });
      this.showPopup = false;
      console.log(JSON.parse(JSON.stringify(saveDetailArr)));
      let requestPayload = {
        userId: this.myDetails.userId,
        gyUserId: this.uId,
        firstName: null,
        middleName: null,
        lastName: null,

        countryCode: this.countryCode,
        contact: this.contactNumber,
        allowCalibrate: this.selectedChoice == "Yes" ? "Y" : "N",
        email: this.myDetails.emailId ? this.myDetails.emailId : null,
        imageUrl: this.myDetails.profilePicUrl ? this.myDetails.profilePicUrl : null,

        roleDetails: saveDetailArr,
        managerId: this.mId ? this.mId : null,
        address: this.myDetails.address ? this.myDetails.address : null
      };
      this.showLoader = true;
      ApiCalls.httpCallDetails(Api.CONFIGUREANDEDITUSER, "post", requestPayload).then((data) => {
        this.showLoader = false;
        this.$router.go(-1);
      });
    },
    discardEdit() {
      this.$router.go(-1);
    },
    // Energy Region
    getRegionEnergy() {
      let url = Api.USERPROFILEENERGYREG;
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        this.EnergyRegionData = data.data;

        console.log("GetEnergyRegionList", this.EnergyRegionData, this.energyRegionId);
        console.log("Call getPlantDataForEnegy REGION ID", this.energyRegionId);
        this.getPlantDataForEnegy(this.regionId, this.plantId);
      });
    },

    // Energy Plant
    getPlantDataForEnegy(regId, plantid) {
      // this.regionId = regId;

      if (this.regionId) {
        let plantPayload = {
          regionIds: [this.regionId],
          year: new Date().getFullYear()
        };
        let url = Api.USERPROFILEENERGYPLANT;
        ApiCalls.httpCallDetails(url, "post", plantPayload).then((data) => {
          console.log("data", data.data);
          this.showLoader = false;
          this.plantId = plantid;
          this.plantDropdownForEnergy = data.data;
        });
        // this.energyPlantId = this.roleplantId ? this.roleplantId : 0;
        // this.plantId = this.energyPlantId;
      } else {
        console.log("Inside getPlantDataForEnegy Else");
        this.plantDropdownForEnergy = [];
        plantid = this.roleplantId ? this.roleplantId : 0;
        this.showLoader = false;
      }
    },
    onchangeEnergyRegion(event) {
      this.showLoader = true;
      this.plantSelected = true;
      this.regionId = event.target.value;
      this.energyPlantId = 0;
      this.getPlantDataForEnegy(this.regionId, 0);
    },
    onchangeEnergyPlant(event) {
      this.plantId = event.target.value;
    }
  }
};
</script>

<style scoped>
#search-user {
  width: 100% !important;
}

.sh-user-con {
  width: 100% !important;
}

.active-tab-hr {
  border-top: 1px lightgrey solid;
  width: 100%;
  margin: 2rem 0;
  margin-left: auto;
  margin-right: auto;
}

.user-role:disabled {
  background-color: rgba(105, 105, 101, 0.1);
}

.req {
  color: red;
}

.no-img-my {
  border-radius: 100% !important;
  border: 1px solid #d6d3d3;
  height: 5rem;
  width: 5rem;
  background: #f4f1de;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-pr-con {
  padding: 2rem;
  background: #fff;
}

.recent-users {
  font-size: 1.333rem;
  font-family: "BarlowM", sans-serif;
  margin-bottom: 0.5rem;
}

.discard-btn {
  color: #274ba7;
  background: transparent;
  border: transparent;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  margin-right: 2rem;
}

.discard-btn:hover {
  color: #274ba7 !important;
  background: transparent !important;
  border: transparent !important;
}

.submit-btn {
  height: 3.3rem;
  width: 9.166666666666666rem;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  background-color: #274ba7;
}

.submit-btn:focus {
  background-color: #274ba7;
}

.add-action-footer-info {
  background: #fff;
  padding-left: 2em !important;
  padding-right: 2em !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.top-padding {
  padding-top: 1rem;
}

.prp-info {
  padding-left: 2em;
  padding-right: 2em;
}

.padding-left {
  margin-left: 20px;
}

.action-info-block {
  background: #fff;
  display: flex;
}

.normal-text {
  color: #313131;
}

.lable-text {
  z-index: 8;
}

.action-desc1 {
  /* margin-left: 30px; */
  border: #313131 1px solid;
  border-radius: 4px;
  background: #fff;
  padding: 4px;
  color: #313131;
}

.font-sb {
  font-family: "BarlowSb", sans-serif;
  font-size: 1.333rem;
  line-height: 1.333rem;
  vertical-align: middle;
  text-align: center;
  padding-top: 12px;
  padding-left: 10px;
}

.mat-info1 {
  padding-left: 0.8rem;
}

.target-label {
  padding-left: 5px;
  display: flex;
  vertical-align: middle;
}

.text-class {
  /* Background:#E5E5E5; */
  background-color: rgba(105, 105, 101, 0.1);
  /* opacity:0.3; */
  border: 1px solid #313131;
  color: #313131;
}

.h4-class {
  font-family: "BarlowM", sans-serif;
  font-size: 1.333rem;
  line-height: 1.333rem;
}

.action-table th div {
  text-align: center !important;
  vertical-align: middle !important;
  font-weight: 100;
}

.action-table:first-child {
  padding: 2px;
  text-align: center !important;
  font-size: 1.166rem;
  font-family: "BarlowM", sans-serif;
  line-height: 1.25rem;
  /* font-weight: 200; */
}

.action-table thead th,
.action-table td {
  text-align: center !important;
  vertical-align: middle !important;
}

.action-table tbody tr,
.action-table td {
  font-family: "BarlowR", sans-serif;
  font-size: 1.1666666666666667rem;
  line-height: 1.6666666666666667rem;
  vertical-align: middle;
  text-align: center;
}

.ellipse-text {
  vertical-align: middle;
  font-family: "BarlowB", sans-serif;
  font-size: 1.3rem;
  color: #ffffff;
  line-height: 1.6666666666666667rem;
}

.principle-name-label {
  margin-top: 5px;
  vertical-align: middle;
  font-size: 1.33rem;
  font-family: "BarlowM", sans-serif;
  line-height: 1.6666666666666667rem;
}

.btn-action {
  background-color: #274ba7;
  font-family: "BarlowM", sans-serif;
  line-height: 1.6666666666666667rem;
  font-size: 1rem;
}

.no-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  height: 8rem;
  justify-content: space-evenly;
}

.ellipse {
  border-radius: 100%;
  width: 26px;
  height: 26px;
  background: #687b9b 0% 0% no-repeat padding-box;

  padding: 3px;
}

.main-con {
  background: #fff;
  margin-top: 1em;
  border-radius: 6px;
}

.mat-info-con {
  padding: 2rem;
  display: flex;
}

.plant-dd {
  width: 30%;
}

.plant-select {
  height: 54px;
  border-color: #313131;
}

.plant-card {
  background: #fffce3;
  height: 54px;
  margin-left: 1.5em;
  text-align: center;
  color: #414141;
  padding: 8px;
  border-radius: 7px;
}

.mm-menu {
  margin-right: 6rem;
}

.level-con {
  padding-left: 15px;
  padding-right: 15px;
}

.level-row {
  text-align: center;
  background: #eff3ff;
  border: 1px solid #ebecef;
  border-radius: 5px 5px 0px 0px;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 10px;
}

.level-status {
  color: #454545;
}

.level-date {
  opacity: 0.7;
}

.modify-date {
  position: absolute;
  top: 167px;
  right: 50px;
}

.dropdown-custome .dropdown-menu {
  min-width: 2rem;
}

.mat-stats-con {
  display: flex;
  padding-left: 2rem;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  align-items: center;
}

.mat-status-con {
  margin-left: 2rem;
  display: flex;
  align-items: center;
}

.asmt-bt {
  border-color: #869ed8 !important;
  background: transparent !important;
  color: #274ba7 !important;
  height: 3.5rem;
}

.asmt-bt:hover {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.last-ast-date {
  padding-top: 0.5rem;
  color: #5a5a5a;
}

.ast-action {
  display: flex;
  justify-content: center;
}

.take-ast-btn {
  margin-right: 1rem;
}

@media only screen and (max-width: 575px) {
  .mat-stats-con {
    display: block !important;
  }

  .mat-status-con {
    margin-left: 0px !important;
  }

  .col-top {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .mat-info-con[data-v-dcabda2e] {
    display: block !important;
  }

  .plant-dd[data-v-dcabda2e] {
    width: 100% !important;
  }

  .plant-card[data-v-dcabda2e] {
    width: 100% !important;
    margin-left: 0em !important;
    margin-top: 1em;
  }

  .modify-date {
    top: 437px !important;
  }
}

.edit-user-imf {
  display: flex;
  vertical-align: middle;
  margin-top: 1rem;
}

.user-field-con {
  margin-top: 2rem;
}

textarea {
  resize: none;
}

.user-search-con {
  background: #fff;
  padding: 2rem;
  border-bottom: 2px solid #e8e8e8;
}

.edit-con {
  position: absolute;
  top: 8rem;
  right: 4.5rem;
}

.profile-sh {
  font-size: 2rem;
  font-family: "BarlowL", sans-serif;
}
</style>
